import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)
  const refGroupListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'user', sortable: true },
    { key: 'email', sortable: true },
    { key: 'role', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const tableGroupColumns = [
    { key: 'group', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const showGroupsLoader = ref(true)
  const showUsersLoader = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  const refetchGroupData = () => {
    refGroupListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    showUsersLoader.value = true
    store
      .dispatch('app-user/fetchUsers', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        showUsersLoader.value = false
        const { users } = response.data
        console.log(users)
        totalUsers.value = Object.keys(users).length
        callback(users)
      })
      .catch(() => {
        showUsersLoader.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchGroups = (ctx, callback) => {
    showGroupsLoader.value = true
    store
      .dispatch('app-user/fetchGroups', {})
      .then(response => {
        console.log('groups', response)
        const groups = []
        response.data.forEach(value => {
          const group = {
            groupName: value,
          }
          groups.push(group)
        })
        showGroupsLoader.value = false
        callback(groups)
      })
      .catch(() => {
        showGroupsLoader.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching groups list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'TenantUser') return 'primary'
    if (role === 'TenantAdmin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'TenantUser') return 'UserIcon'
    if (role === 'TenantAdmin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserRole = role => {
    if (role === 'TenantUser') return 'Employee'
    if (role === 'TenantAdmin') return 'Admin'
    return 'Employee'
  }

  const resolveUserStatusText = (status, enabled) => {
    if (enabled) {
      // if (role === 'TenantUser') return 'Ready' // Users dont login yet so hide the password status
      if (status === 'FORCE_CHANGE_PASSWORD') return 'Force Change Password'
      if (status === 'PASSWORD_RESET_REQUIRED') return 'Password Reset Required'
      if (status === 'CONFIRMED') return 'Active'
      if (status === 'REGISTERED') return 'Pending'
      return 'Unknown?'
    }
    return 'Suspended'
  }

  const resolveUserStatusVariant = (status, enabled, role) => {
    if (enabled) {
      if (role === 'TenantUser') return 'success' // Users dont login yet so hide the password status
      if (status === 'FORCE_CHANGE_PASSWORD') return 'warning'
      if (status === 'PASSWORD_RESET_REQUIRED') return 'warning'
      if (status === 'CONFIRMED') return 'success'
      if (status === 'REGISTERED') return 'secondary'
      return 'primary'
    }
    return 'danger'
  }

  return {
    fetchUsers,
    fetchGroups,
    tableColumns,
    tableGroupColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    refGroupListTable,

    showUsersLoader,
    showGroupsLoader,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserRole,
    resolveUserStatusVariant,
    resolveUserStatusText,
    refetchData,
    refetchGroupData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
