import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        console.log(queryParams)
        axios
          .get('/user/list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/user', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    enableUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/user/${id}/enable`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    disableUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/user/${id}/disable`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/user/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteGroup(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/group/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/user/${userData.userName}`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadUserFile(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/user/upload',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUserGroups(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/${id}/group`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGroups(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        console.log(queryParams)
        axios
          .get('/group')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addGroupToUser(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/user/${data.userName}/group`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeGroupFromUser(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/user/${data.userName}/group`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addGroup(ctx, groupData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/group', groupData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editGroup(ctx, groupData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/group/${groupData.originalGroupName}`, groupData.groupData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
